import {
  SET_IS_AUTH,
  SET_USER_TOKEN,
  SET_USER_REFRESHTOKEN,
  SET_AUTH_USER,
  SET_CURRENT_USER,
  SET_FORGOTPASSWORD_USER,
  IS_ADD_USER_AVAIABLE,
  SET_USER_CREATE_AVAILABLE,
  SET_INACTIVE_USER,
  SET_LANG,
} from "../types";
import i18n, { getTranslation } from "../../../i18n/i18n";
import toast from 'react-hot-toast';
import { genaretPublicKey, createEncryptString } from "./loginActions";
import { api } from "../../../api/api";
import { history } from "../../../history";
import { GlobalVariable } from "../../../util/index";
import { setLoaderAction } from "../loader/index";
import { getSetting } from "../setting/index";
import { permission } from "./permissionDefaultData";
import { getProfile } from "./loginActions";
import { NotificationApi } from "../../../api/notificationApi";
import { encryptStorage } from "../../../util/encryptStorage";
import * as constantData from "../../../constants";

const api_status = GlobalVariable.apiResponseStatus;

export const setIsLoginAction = (value) => {
  return {
    type: SET_IS_AUTH,
    payload: value,
  };
};

export const setAuthTokenAction = (value) => {
  return {
    type: SET_USER_TOKEN,
    payload: value,
  };
};

export const setAuthRefreshTokenAction = (value) => {
  return {
    type: SET_USER_REFRESHTOKEN,
    payload: value,
  };
};

export const setLoginUserAction = (value) => {
  return {
    type: SET_AUTH_USER,
    payload: value,
  };
};

export const setCurrentUser = (value) => {
  return {
    type: SET_CURRENT_USER,
    payload: value,
  };
};

export const setForgotPasswordUserAction = (value) => {
  return {
    type: SET_FORGOTPASSWORD_USER,
    payload: value,
  };
};

export const addUserAvailableAction = (value) => {
  return {
    type: IS_ADD_USER_AVAIABLE,
    payload: value,
  };
};

export const setAvailableAddUserCountAction = (value) => {
  return {
    type: SET_USER_CREATE_AVAILABLE,
    payload: value,
  };
};
export const setActiveUSerAvailble = (value) => {
  return {
    type: SET_INACTIVE_USER,
    payload: value,
  };
};

/**
 @name  resetPasswordAction
 @file authActions.js
 @description This method is used for re-setting creds
 */
export const resetPasswordAction = (data) => {
  return async (dispatch, store) => {
    await dispatch(genaretPublicKey());
    if (!data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.passwordRequired"));
      return true;
    }

    if (!data.confirmPassword) {
      toast.error(getTranslation("GLOBLE_MESSAGE.cPasswordRequired"));
      return true;
    }

    if (data.confirmPassword !== data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.password&ConfirmNotSame"));
      return true;
    }
    let encrytPassword = await createEncryptString(data.password);

    let passwordData = {
      password: encrytPassword.encryt,
      confirmPassword: encrytPassword.encryt,
      keyId: store()?.auth?.login?.publickKey?.id,
      key: encrytPassword.frontEndKey,
    };
    let setResetPassword = await api(`admin/reset`, passwordData, "put");

    if (setResetPassword.status === api_status.success) {
      encryptStorage.removeItem(GlobalVariable.merchantToken);
      encryptStorage.removeItem(GlobalVariable.isPChanged);
      toast.success(getTranslation("GLOBLE_MESSAGE.passwordChange"));
      history.push("/login");
      return true;
    } else {
      toast.error(setResetPassword.message);
    }
  };
};

/**
 @name  checkApiUrl
 @file authActions.js
 @description This method is used for checking url addresss is valid or not
 */
export const checkApiUrl = async (api) => {
  if (!constantData.urlRegex.test(api)) {
    toast.error(getTranslation("SETTING.PleaseAddValidAPIAddress"));
    return true;
  }
};

/**
 @name  verifyOtpandLogin
 @file authActions.js
 @description This method is verify otp and return data same as before login api  
 */
export const verifyOtpandLogin = (otp) => {
  // resend-otp
  return async (dispatch, store) => {
    if (!otp) {
      toast.error(getTranslation("GLOBLE_MESSAGE.codeRequired"));
      return true;
    }
    if (otp.length !== 6) {
      toast.error(getTranslation("GLOBLE_MESSAGE.codeNotValid"));
      return true;
    }
    let otpData = {
      otp: otp,
    };
    let loggedIn = false;
    let data = await api("admin/veriy-otp", otpData, "post");
    if (data.status === api_status.success) {
      if (data.data.data.role === "merchant") {
        encryptStorage.setItem(
          GlobalVariable.loginId,
          data.data.data.merchant.id
        );
        let localData = {
          merchantID: data.data.data.merchant.id,
          name: data.data.data.merchant.name,
          logoURL: data.data.data.merchant.logoURL,
          categoryID: data.data.data.merchant.categoryID,
          passwordPolicy: data.data.data.merchant.passwordPolicy,
          segmentation: data.data.data.merchant.segmentation,
          exposeAPI: data.data.data.merchant.exposeAPI,
          ThirdPartySignageAPI: data.data.data.merchant.ThirdPartySignageAPI,
          email: data.data.data.email,
          isSignedIn: loggedIn,
          loggedInWith: "firebase",
          isDonate: data?.data?.data?.isDonate,
          role: data.data.data.role,
          permission: permission,
          token: data.data.data.token,
          refreshToken: "",
        };
        await dispatch(setAuthTokenAction(data.data.data.token));
        await dispatch(setAuthRefreshTokenAction(""));
        encryptStorage.setItem(
          GlobalVariable.localStoreName,
          JSON.stringify(localData)
        );
        await dispatch(setLoginUserAction(localData));
        await dispatch(getProfile(data.data.data.merchant.name));
        await dispatch(getSetting());
        dispatch(setIsLoginAction(true));
        dispatch(setLoaderAction(false));
        if (data?.data?.data?.isDonate) {
          history.push("/donationDashboard");
        } else {
          history.push("/");
        }
      } else if (data.data.data.role === GlobalVariable.manager) {
        encryptStorage.setItem(
          GlobalVariable.loginId,
          data.data.data.merchant.id
        );
        let localData = {
          merchantID: data.data.data.merchant.id,
          branchId: data.data.data.branchID,
          serviceId: data.data.data.serviceID,
          queueId: data.data.data.queueId,
          id: data.data.data.id,
          branchName: data.data.data.branch.name,
          name: data.data.data.name,
          role: data.data.data.role,
          logoURL: data.data.data.merchant.logoURL,
          categoryID: data.data.data.merchant.categoryID,
          email: data.data.data.email,
          passwordPolicy: data.data.data.merchant.passwordPolicy,
          segmentation: data.data.data.merchant.segmentation,
          exposeAPI: data.data.data.merchant.exposeAPI,
          ThirdPartySignageAPI: data.data.data.merchant.ThirdPartySignageAPI,
          isSignedIn: loggedIn,
          loggedInWith: "firebase",
          token: data.data.data.token,
          permission: data.data.data.permission,
          refreshToken: "",
        };

        await dispatch(setAuthTokenAction(data.data.data.token));
        await dispatch(setAuthRefreshTokenAction(""));
        encryptStorage.setItem(
          GlobalVariable.localStoreName,
          JSON.stringify(localData)
        );
        await dispatch(setLoginUserAction(localData));
        await dispatch(getProfile(data.data.data.name));
        await dispatch(getSetting());
        dispatch(setIsLoginAction(true));
        dispatch(setLoaderAction(false));
        const permission = store().auth.login.user.permission;
        let dashboardTab = permission && permission[0].dashboardTab.read;
        let appointmentsDashboardTab = permission && permission[14].appointmentsDashboardTab.read;
        let eservicesDashboardTab = permission && permission[15].eservicesDashboardTab.read;
        let serviceTab = permission && permission[1].serviceTab.read;
        let queueTab = permission && permission[2].queueTab.read;
        let userTab = permission && permission[3].userTab.read;
        let branchTab = permission && permission[4].branchTab.read;
        let feedbackTab = permission && permission[5].feedbackTab.read;
        let settingTab = permission && permission[6].settingTab.read;
        let logTab = permission && permission[7].logTab.read;
        let instantTicketsLogTab = permission && permission[16].instantTicketsLogTab.read;
        let appointmentsLogTab = permission && permission[17].appointmentsLogTab.read;
        let eservicesLogTab = permission && permission[18].eservicesLogTab.read;
        let reportTab = permission && permission[8]?.reportTab?.read;
        let workProfileTab = permission && permission[9]?.workProfileTab?.read;
        let categoryTab = permission && permission[10]?.categoryTab?.read;
        let formTab = permission && permission[12]?.formTab?.read;
        let livefeedTab = permission && permission[13].livefeedTab.read;
        let donationDashboardTab = permission && permission[19]?.donationDashboardTab?.read;
        let donationLogTab = permission && permission[20]?.donationLogTab?.read;
        let pauseReasonsLogTab = permission && permission[22]?.pauseReasonsLogTab?.read;
        if (dashboardTab) {
          history.push("/");
          return true;
        }
        if (appointmentsDashboardTab) {
          history.push("/appointmentDashboard");
          return true;
        }
        if (eservicesDashboardTab) {
          history.push("/eserviceDashboard");
          return true;
        }
        if (donationDashboardTab) {
          history.push("/donationDashboard");
          return true;
        }
        if (serviceTab) {
          history.push("/services");
          return true;
        }
        if (queueTab) {
          history.push("/queues");
          return true;
        }
        if (userTab) {
          history.push("/users");
          return true;
        }
        if (branchTab) {
          history.push("/branches");
          return true;
        }
        if (reportTab) {
          history.push("/reports");
          return true;
        }
        if (feedbackTab) {
          history.push("/feedback");
          return true;
        }
        if (settingTab) {
          history.push("/setting");
          return true;
        }
        if (logTab) {
          history.push("/logs");
          return true;
        }
        if (instantTicketsLogTab) {
          history.push("/ticketLogs");
          return true;
        }
        if (appointmentsLogTab) {
          history.push("/appointmentsLogs");
          return true;
        }
        if (eservicesLogTab) {
          history.push("/eservicesLogs");
          return true;
        }
        if (donationLogTab) {
          history.push("/donationLogs");
          return true;
        }
        if(pauseReasonsLogTab){
          history.push("/pauseReasonsLogs");
          return true
        }
        if (reportTab) {
          history.push("/reports");
          return true;
        }
        if (workProfileTab) {
          history.push("/work-profile");
          return true;
        }
        if (categoryTab) {
          history.push("/category");
          return true;
        }
        if (formTab) {
          history.push("/forms");
          return true;
        }
        if (livefeedTab) {
          history.push("/livefeed");
          return true;
        }
        history.push("/not-access");
      } else if (data.data.data.role === GlobalVariable.merchantUser) {
        encryptStorage.setItem(
          GlobalVariable.loginId,
          data.data.data.merchant.id
        );

        let localData = {
          merchantID: data.data.data.merchant.id,
          name: data.data.data.name,
          logoURL: data.data.data.merchant.logoURL,
          categoryID: data.data.data.merchant.categoryID,
          passwordPolicy: data.data.data.merchant.passwordPolicy,
          segmentation: data.data.data.merchant.segmentation,
          exposeAPI: data.data.data.merchant.exposeAPI,
          ThirdPartySignageAPI: data.data.data.merchant.ThirdPartySignageAPI,
          email: data.data.data.email,
          isSignedIn: loggedIn,
          loggedInWith: "firebase",
          role: data.data.data.role,
          permission: data.data.data.permission,
          token: data.data.data.token,
          refreshToken: "",
        };
        await dispatch(setAuthTokenAction(data.data.data.token));
        await dispatch(setAuthRefreshTokenAction(""));
        encryptStorage.setItem(
          GlobalVariable.localStoreName,
          JSON.stringify(localData)
        );
        await dispatch(setLoginUserAction(localData));
        await dispatch(getProfile(data.data.data.name));
        await dispatch(getSetting());
        dispatch(setIsLoginAction(true));
        dispatch(setLoaderAction(false));
        const permission = store().auth.login.user.permission;
        let dashboardTab = permission && permission[0].dashboardTab.read;
        let appointmentsDashboardTab = permission && permission[14].appointmentsDashboardTab.read;
        let eservicesDashboardTab = permission && permission[15].eservicesDashboardTab.read;
        let serviceTab = permission && permission[1].serviceTab.read;
        let queueTab = permission && permission[2].queueTab.read;
        let userTab = permission && permission[3].userTab.read;
        let branchTab = permission && permission[4].branchTab.read;
        let feedbackTab = permission && permission[5].feedbackTab.read;
        let settingTab = permission && permission[6].settingTab.read;
        let logTab = permission && permission[7].logTab.read;
        let instantTicketsLogTab = permission && permission[16].instantTicketsLogTab.read;
        let appointmentsLogTab = permission && permission[17].appointmentsLogTab.read;
        let eservicesLogTab = permission && permission[18].eservicesLogTab.read;
        let reportTab = permission && permission[8]?.reportTab?.read;
        let workProfileTab = permission && permission[9]?.workProfileTab?.read;
        let categoryTab = permission && permission[10]?.categoryTab?.read;
        let formTab = permission && permission[12]?.formTab?.read;
        let livefeedTab = permission && permission[13].livefeedTab.read;
        let donationDashboardTab = permission && permission[19]?.donationDashboardTab?.read;
        let donationLogTab = permission && permission[20]?.donationLogTab?.read;
        let pauseReasonsLogTab = permission && permission[22]?.pauseReasonsLogTab?.read;
        if (dashboardTab) {
          history.push("/");
          return true;
        }
        if (appointmentsDashboardTab) {
          history.push("/appointmentDashboard");
          return true;
        }
        if (eservicesDashboardTab) {
          history.push("/eserviceDashboard");
          return true;
        }
        if (donationDashboardTab) {
          history.push("/donationDashboard");
          return true;
        }
        if (serviceTab) {
          history.push("/services");
          return true;
        }
        if (queueTab) {
          history.push("/queues");
          return true;
        }
        if (userTab) {
          history.push("/users");
          return true;
        }
        if (branchTab) {
          history.push("/branches");
          return true;
        }
        if (reportTab) {
          history.push("/reports");
          return true;
        }
        if (feedbackTab) {
          history.push("/feedback");
          return true;
        }
        if (settingTab) {
          history.push("/setting");
          return true;
        }
        if (logTab) {
          history.push("/logs");
          return true;
        }
        if (instantTicketsLogTab) {
          history.push("/ticketLogs");
          return true;
        }
        if (appointmentsLogTab) {
          history.push("/appointmentsLogs");
          return true;
        }
        if (eservicesLogTab) {
          history.push("/eservicesLogs");
          return true;
        }
        if (donationLogTab) {
          history.push("/donationLogs");
          return true;
        }
        if(pauseReasonsLogTab){
          history.push("/pauseReasonsLogs");
          return true
        }
        if (reportTab) {
          history.push("/reports");
          return true;
        }
        if (workProfileTab) {
          history.push("/work-profile");
          return true;
        }
        if (categoryTab) {
          history.push("/category");
          return true;
        }
        if (formTab) {
          history.push("/forms");
          return true;
        }
        if (livefeedTab) {
          history.push("/livefeed");
          return true;
        }
        history.push("/not-access");
      } else {
        toast.error("Invalid credentials");
        dispatch(setLoaderAction(false));
      }
    } else if (
      data.status === api_status.error &&
      data.message.data.isPasswordChanged === true
    ) {
      encryptStorage.setItem(
        GlobalVariable.isPChanged,
        data.message.data.isPasswordChanged
      );
      encryptStorage.setItem(
        GlobalVariable.merchantToken,
        data.message.data.token.token
      );
      dispatch(setAuthTokenAction(data.message.data.token.token));
      await dispatch(setLoaderAction(false));
      history.push("/change-password");
      toast.error(data.message.message);
    } else {
      dispatch(setLoaderAction(false));
      if (data.message.message) {
        toast.error(data.message.message);
      } else {
        toast.error(data.message);
      }
    }
  };
};

/**
 @name  errorDetection
 @file authActions.js
 @description if there any error while api is running send to backend 
 */
export const errorDetection = async (data) => {
  let { serviceName, api, osType, appVersion, errorDetail, userId } = data;
  const errorData = {
    serviceName,
    api,
    osType,
    appVersion,
    errorDetail,
    userId,
  };
  await NotificationApi("errorLog", errorData, "post");
};

/**
 @name  forgotPasswordSendTokenAction
 @file authActions.js
 @description This method is used for sent token to mail id 
 */
export const forgotPasswordSendTokenAction = (data) => {
  return async (dispatch, store) => {
    if (!data) {
      toast.error(getTranslation("GLOBLE_MESSAGE.emailRequired"));
      return true;
    }
    if (!data.match(constantData.EMAIL_VALIDATION)) {
      toast.error(getTranslation("GLOBLE_MESSAGE.invalidEmail"));
      return true;
    }
    dispatch(setLoaderAction(true));
    let forgotData = await api(
      "user/forgetpassword",
      { email: data, role: "merchant" },
      "put"
    );

    if (forgotData.status === api_status.success) {
      sessionStorage.setItem(GlobalVariable.userEmail, data);
      dispatch(setLoaderAction(false));
      dispatch(setForgotPasswordUserAction(forgotData.data.data));
      toast.success(getTranslation("GLOBLE_MESSAGE.codeSendMsg"));
      history.push("/forgot-password/confirm");
    } else {
      dispatch(setLoaderAction(false));
      toast.error(forgotData.message);
    }
  };
};

/**
 @name  resetPassword
 @file authActions.js
 @description This method is used for reset creds 
 */
export const resetPassword = (data) => {
  return async (dispatch, store) => {
    if (!data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.passwordRequired"));
      return true;
    }

    if (!data.confirmPassword) {
      toast.error(getTranslation("GLOBLE_MESSAGE.cPasswordRequired"));
      return true;
    }

    if (data.confirmPassword !== data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.password&ConfirmNotSame"));
      return true;
    }
    await dispatch(genaretPublicKey());
    let encrytPassword = await createEncryptString(data.password);

    let passwordData = {
      password: encrytPassword.encryt,
      confirmPassword: encrytPassword.encryt,
      keyId: store()?.auth?.login?.publickKey?.id,
      key: encrytPassword.frontEndKey,
    };
    let setResetPassword = await api(`admin/reset`, passwordData, "put");

    if (setResetPassword.status === api_status.success) {
      encryptStorage.removeItem(GlobalVariable.merchantToken);
      encryptStorage.removeItem(GlobalVariable.isPChanged);
      toast.success(getTranslation("GLOBLE_MESSAGE.passwordChange"));
      if (data.isChangePassword === true) {
        history.push("/login");
        return true;
      }
      history.push("/password/reset");
    } else {
      toast.error(setResetPassword.message);
    }
  };
};

/**
 @name  getLoginUserData
 @file authActions.js
 @description get details of login user and set to redux store  
 */
export const getLoginUserData = (id) => {
  return async (dispatch, store) => {
    let userData = await api(`user/${id}`, {}, "get");

    if (userData.status === api_status.success) {
      return userData.data?.data;
    } else {
      toast.error(userData.message);
    }
  };
};



export const setLanguage = (value) => {
  return async (dispatch, store) => {
    dispatch({
      type: SET_LANG,
      payload: value,
    });
  };
};

export const sendRestPasswordEmail = (email) => {
  return async (dispatch, store) => {
    try {
      if (!email) {
        toast.error(getTranslation("GLOBLE_MESSAGE.emailRequired"));
        return true;
      }
      if (!email.match(constantData.EMAIL_VALIDATION)) {
        console.log('email: ', email);
        toast.error(getTranslation("GLOBLE_MESSAGE.invalidEmail"));
        return true;
      }
      dispatch(setLoaderAction(true));
      let sendToData = {
        email,
        panel: 'merchant',
      }
      let respones = await api(`v2/user/forget-password`, sendToData, "post")
      if (respones.status === api_status.success) {
        toast.success(respones?.data?.message)
        dispatch(setLoaderAction(false));
        history.push("/login")
      } else {
        toast.error(respones?.message?.message)
        dispatch(setLoaderAction(false));
        return false
      }
    } catch {

    }
  }
}

export const verifyResetPasswordLink = (data) => {
  return async (dispatch, store) => {
    try {
      let respones = await api(`v2/user/verify-forgetpwd-url`, data, "post")
      if (respones.status === api_status.success) {
        return true
      } else {
        toast.error(respones?.message?.message)
        return false
      }
    } catch {
      return false
    }
  }
} 

export const submitResetPasswordAction = (data) => {
  return async (dispatch, store) => {
    await dispatch(genaretPublicKey());

    if (!data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.passwordRequired"));
      return true;
    }

    if (!data.confirmPassword) {
      toast.error(getTranslation("GLOBLE_MESSAGE.cPasswordRequired"));
      return true;
    }

    if (data.confirmPassword !== data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.password&ConfirmNotSame"));
      return true;
    }
    let encrytPassword = await createEncryptString(data.password);
    let finalData = {
      id: data.id,
      keyId: store()?.auth?.login?.publickKey?.id,
      key: encrytPassword.frontEndKey,
      password: encrytPassword.encryt,
      confirmPassword: encrytPassword.encryt,
      token: data.token,
    };
    dispatch(setLoaderAction(true));
    let setPassword = await api(
      `v2/user/set-forgotpwd`,
      finalData,
      "post"
    );

    if (setPassword.status === api_status.success) {
      dispatch(setForgotPasswordUserAction({}));
      toast.success(getTranslation("GLOBLE_MESSAGE.passwordChange"));
      dispatch(setLoaderAction(false));
      history.push("/login");
    } else {
      toast.error(setPassword.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};