import { SET_SETTING, SET_ABUSE, SET_PAUSE_REASONS } from "../../actions/types";

const initState = {
  setting: {},
  abuse: [],
  pauseReasons:[]
};

const setting = (state = initState, action) => {
  switch (action.type) {
    case SET_SETTING: {
      return { ...state, setting: action.payload };
    }
    case SET_ABUSE: {
      return { ...state, abuse: action.payload };
    }
    case SET_PAUSE_REASONS: {
      return { ...state, pauseReasons: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default setting;
